import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home-container">
      <h1>SnapConvert</h1>
      <p>Alles auf einen Klick</p>
      {/* Navigationsleiste wurde hier entfernt, da sie im Header angezeigt wird */}
    </div>
  );
}

export default Home;
