import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import ImageConverter from './components/ImageConverter';
import VideoConverter from './components/VideoConverter';
import YouTubeDownloader from './components/YouTubeDownloader';
import BackgroundRemover from './components/BackgroundRemover';
import TikTokDownloader from './components/TikTokDownloader';
import Logo from './components/Logo';  
import Header from './components/Header';  
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="header-container">
          <Logo /> {/* Logo links */}
          <Header /> {/* Header rechts */}
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/image-conversion" element={<ImageConverter />} />
          <Route path="/video-conversion" element={<VideoConverter />} />
          <Route path="/youtube-download" element={<YouTubeDownloader />} />
          <Route path="/tiktok-download" element={<TikTokDownloader />} />
          <Route path="/background-removal" element={<BackgroundRemover />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
