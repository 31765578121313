import React, { useState } from 'react';
import './styles.css';

function TikTokDownloader() {
  const [link, setLink] = useState('');
  const [format, setFormat] = useState('mp4');  // Standardmäßig MP4
  const [status, setStatus] = useState('');     // Statusanzeige für den Download

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Download wird gestartet...');

    try {
      const response = await fetch('/api/download-tiktok', {  // Korrigiert die Route
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: link,  // Stelle sicher, dass im Backend 'url' verwendet wird
          format,
        }),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `video.${format}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setStatus('Download abgeschlossen.');
      } else {
        setStatus('Fehler beim Herunterladen. Bitte erneut versuchen.');
      }
    } catch (error) {
      console.error('Fehler beim Herunterladen:', error);
      setStatus('Fehler beim Herunterladen. Bitte erneut versuchen.');
    }
  };

  return (
    <div className="component-container">
      <h2>TikTok Video herunterladen</h2>
      <form onSubmit={handleSubmit} className="input-group">
        <input
          type="text"
          placeholder="Füge den TikTok-Link hier ein"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          required
        />
        <select value={format} onChange={(e) => setFormat(e.target.value)}>
          <option value="mp4">MP4</option>
          <option value="mp3">MP3</option>
        </select>
        <button type="submit">Herunterladen</button>
      </form>
      <p>{status}</p>
    </div>
  );
}

export default TikTokDownloader;