import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Pfad zu deinem Logo

function Logo() {
  return (
    <div className="logo-container">
      <Link to="/">
        <img src={logo} alt="Logo" className="logo-image" />
      </Link>
    </div>
  );
}

export default Logo;
