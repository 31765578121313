import React, { useState } from 'react';
import './styles.css';

function ImageConverter() {
  const [file, setFile] = useState(null);
  const [format, setFormat] = useState('png');
  const [status, setStatus] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Bildkonvertierung wird gestartet...');

    const formData = new FormData();
    formData.append('file', file);
    formData.append('format', format);

    try {
      const response = await fetch('/convert-image', {  // Route zum Backend
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `converted.${format}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setStatus('Bildkonvertierung abgeschlossen.');
      } else {
        setStatus('Fehler bei der Konvertierung.');
      }
    } catch (error) {
      console.error('Fehler bei der Bildkonvertierung:', error);
      setStatus('Fehler bei der Konvertierung.');
    }
  };

  return (
    <div className="component-container">
      <h2>Bildkonvertierung</h2>
      <div
        className="drag-drop-area"
        onClick={() => document.getElementById('fileInput').click()}
      >
        {file ? <p>{file.name}</p> : <p>Klicken oder Datei hierher ziehen</p>}
      </div>
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <form onSubmit={handleSubmit} className="input-group">
        <select value={format} onChange={(e) => setFormat(e.target.value)}>
          <option value="png">PNG</option>
          <option value="jpg">JPG</option>
          <option value="gif">GIF</option>
        </select>
        <button type="submit">Konvertieren</button>
      </form>
      <p>{status}</p>
    </div>
  );
}

export default ImageConverter;