import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <div className="header-container">
      <div className="navigation">
        <Link to="/image-conversion">Bildkonvertierung</Link>
        <Link to="/video-conversion">Videokonvertierung</Link>
        <Link to="/youtube-download">YouTube-Download</Link>
        <Link to="/tiktok-download">TikTok-Download</Link>
        <Link to="/background-removal">Hintergrund entfernen</Link>
      </div>
    </div>
  );
}

export default Header;
